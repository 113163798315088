/*<summary >
/Author: Juin
</summary >*/
.pageLayout {
    margin: 10px 25px;
}

.app {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    /* border: 1px solid grey; */
    background-color: #f4f6fa;
    -webkit-font-smoothing: antialiased;
    margin: 0 auto;
    max-width: 650px;
    min-height: fit-content;
    padding-bottom: 80px;
}

.minheight {
    height: 90vh;
}

.minheight80 {
    height: 80vh;
}

.width100 {
    width: 100%;
}

.height100 {
    height: 100%;
}

.inline {
    display: inline-block;
}

.font9 {
    font-size: 9pt;
}

.font10 {
    font-size: 10pt;
}

.font11 {
    font-size: 11pt;
}

.font12 {
    font-size: 12pt;
}

.radius20 {
    border-radius: 20px;
}

.radius30 {
    border-radius: 30px;
}

.whitebg {
    background-color: white;
}

.padding20 {
    padding: 20px;
}

.padding30 {
    padding: 30px;
}

.whitebox {
    background: white;
    border-radius: 30px;
    padding: 25px 30px;
    height: 100%;
    overflow: auto;
    /* min-height: -webkit-fill-available; */
}

.whitebox2 {
    background: white;
    border-radius: 30px;
    padding: 25px 30px;
}

.whitebox3 {
    background: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 25px 30px;
    height: 100%;
    /* min-height: fit-content; */
}
.receiptapp {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: #f4f6fa;
    -webkit-font-smoothing: antialiased;
    margin: 0 auto;
    max-width: 650px;
    min-height: -webkit-fill-available;
}

.receiptbox {
    background: white;
    border-radius: 30px;
    padding: 10px 20px;
    /* margin-top: 5px; */
    min-height: -webkit-fill-available;
}

.gridbox {
    background: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 15px 35px;
}

@media (min-width: 429px) {
    .loginimgmin {
        width: 300px;
        height: 300px;
    }
}

@media (max-width: 429px) {
    .loginimgmax {
        width: 220px;
        height: 220px;
    }
}

@media (max-width: 349px) {
    .loginimgxmin {
        width: 160px;
        height: 160px;
    }

    .loginfontxmin {
        font-size: 80%;
    }
}


@media only screen and (max-width: 380px) {

    /* horizontal scrollbar for tables if mobile screen */
    .countrytable {
        overflow-x: auto;
        display: block;
    }
}

.textcenter {
    text-align: center;
}

.textright {
    text-align: right;
}

.floatleft {
    float: left;
}

.floatright {
    float: right;
}

.logincode {
    background-color: #1c1454;
    color: #bb5657;
    border-radius: 50px;
    width: auto;
    padding: 5px 10px;
    font-style: italic;
    font-size: 12pt;
}

.loginreload {
    color: #9b89f0;
    width: auto;
    font-size: 10pt;
    float: left;
    line-height: 30px;
    cursor: pointer;
}

@media (max-width:330px) {
    .loginleft {
        float: left;
    }

    .rmbwidth {
        width: 200px;
    }
}

.height2 {
    height: 2px;
}

.lineheight0 {
    line-height: 0;
}

.lineheight2 {
    line-height: 2;
}

.lineheight5 {
    line-height: 5px;
}

.lineheight10 {
    line-height: 10px;
}

.lineheight15 {
    line-height: 15px;
}

.lineheight60 {
    line-height: 60px;
}

.grey {
    color: #7b7b7b;
}

.inputdiv {
    width: 100%;
    border-radius: 20px;
    padding: 20px;
    background-color: #f4f6fa;
}

.inputdiv:hover {
    cursor: pointer;
}

.inputdivbox {
    border-radius: 20px;
    width: 90%;
    outline: none;
    background-color: #f4f6fa;
    border: none;
    font-size: 10pt;
}

.inputbox {
    border-radius: 20px;
    width: 100%;
    outline: none;
    border: none;
    font-size: 10pt;
    background-color: #f4f6fa;
    padding: 20px;
}

.inputbtn {
    text-align: left;
    border-radius: 20px;
    /* width: 95%; */
    outline: none;
    background-color: #f4f6fa;
    border: none;
    font-size: 10pt;
    color: black;
}

.inputbtn:focus {
    outline: none;
}

.inputbtnbig {
    width: auto;
    padding: 10px 35px;
    border: none;
    color: #6c48cb;
    background-color: #f1eefa;
    font-weight: bold;
    border-radius: 30px;
    font-size: 12pt;
}

.aligncenter {
    align-items: center;
}

.profileinfo {
    float: right;
    color: #a5a5a6;
    font-weight: normal;
    margin-right: 3px;
    font-size: 10pt;
    padding-top: inherit;
}

.normalweight {
    font-weight: normal;
}

.boldweight {
    font-weight: bold;
}

.margin25 {
    margin: 25px;
}

.margintop10 {
    margin-top: 10px;
}

.margintop15 {
    margin-top: 15px;
}

.margintop20 {
    margin-top: 20px;
}

.margintop60 {
    margin-top: 60px;
}

.margintop80 {
    margin-top: 80px;
}

.marginbottom5 {
    margin-bottom: 5px;
}

.marginbottom10 {
    margin-bottom: 10px;
}

.marginbottom15 {
    margin-bottom: 15px;
}

.marginbottom20 {
    margin-bottom: 20px;
}

.marginbottom25 {
    margin-bottom: 25px;
}

.marginbottom30 {
    margin-bottom: 30px;
}

.marginbottom40 {
    margin-bottom: 40px;
}

.marginbottom60 {
    margin-bottom: 60px;
}

.marginbottom80 {
    margin-bottom: 80px;
}

.marginbottom100 {
    margin-bottom: 100px;
}

.marginbottom150 {
    margin-bottom: 150px;
}

.headerdiv {
    /* margin: 20px; */
    text-align: center;
    position: fixed;
    max-width: 650px;
    width: 100%;
    /* height: auto; */
    padding-top: 14px;
    background-color: #f4f6fa;
    z-index: 99;
}

.transheaderdiv {
    text-align: center;
    position: fixed;
    max-width: 650px;
    width: 93%;
    padding-bottom: 10px;
    z-index: 99;
    background-color: white;
}

.headerarrow {
    float: left;
    margin-left: 25px;
    background-color: white;
    border-radius: 30px;
    min-width: 25px;
    min-height: 25px;
}

.headerarrowfilter {
    float: left;
    margin-left: 25px;
    background-color: #f4f6fa;
    border-radius: 30px;
    min-width: 25px;
    min-height: 25px;
    text-align: center;
}

.headername {
    text-align: center;
    margin-right: 35px;
}

.headernamefilter {
    text-align: center;
    margin-right: 50px;
}

.headericon {
    float: left;
    display: flex;
    margin-left: auto;
}

.filtercontent {
    padding: 0px 15px;
    padding-top: 40px;
    overflow-y: auto;
    height: 100%;
}

.navstyle {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 15px 8px;
    box-shadow: 0px -6px 20px lightgray;
    max-width: 650px;
    max-height: 80px;
}

.navactive {
    margin-top: 5px;
    margin-bottom: 0px;
    color: #8062d2;
    font-weight: normal;
}

.navinactive {
    margin-top: 5px;
    margin-bottom: 0px;
    color: #d2c7ef;
    font-weight: normal;
}

.boxdiv {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.lgactive {
    border-radius: 20px;
    width: 100%;
    border: none;
    font-size: 10pt;
    background-color: #6c48cb;
    color: white;
    padding: 20px;
    text-align: left;
}

.lg {
    border-radius: 20px;
    width: 100%;
    border: none;
    font-size: 10pt;
    background-color: #f4f6fa;
    padding: 20px;
    text-align: left;
    color: black;
}

.lg:focus {
    outline: none;
}

.lgactive:focus {
    outline: none;
}

.dashtablefont {
    color: #7b7b7b;
    font-weight: normal;
}

.dashboard-header {
    padding-left: 15px;
    padding-right: 15px;
}

.searchdiv {
    border-radius: 15px;
    padding: 3px 10px;
    border: none;
    background-color: #e9e9e9;
    width: 100%;
}

.searchinput {
    outline: none;
    border-radius: 15px;
    margin-left: 5px;
    border: none;
    background-color: #e9e9e9;
    max-width: 100%;
}

.dashstyle {
    width: auto;
    height: auto;
    border-radius: 30px;
    padding-right: 8px;
    max-height: 135px;
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 30px;
}

.profit {
    color: #56aa68;
}

.loss {
    color: #ff2c2c;
}

@media (max-width:350px) {
    .dashfont {
        font-size: 60%;
    }
}

@media (min-width: 289px) and (max-width:350px) {
    .marginbottom5ss {
        margin-bottom: 5px;
    }
}

/* 530 */
@media (max-width:432px) {
    .marginbottom5s {
        margin-bottom: 5px;
    }
}

@media (min-width: 278px) and (max-width:289px) {
    .marginbottom5sss {
        margin-bottom:5px;
    }
}

.dashfontstyle {
    color: white;
    font-size: 10pt;
    font-weight: normal;
    width: 100%;
}


.pricestyle {
    color: white;
    font-weight: bold;
    word-wrap: break-word;
    font-size: 2.3rem;
}

/* .dashstyle2 {
   background-color: white;
   width: auto;
   height: auto;
   border-radius: 30px;
   padding: 20px;
} */
/* 
.dashfontstyle2 {
   margin-top: 20px;
   color: #7b7b7b;
   font-size: 10pt;
   font-weight: normal;
   line-height: 3px;
} */

/* .pricestyle2 {
   color: grey;
   font-weight: 550;
} */

.greyrow {
    background-color: #e9e9e9;
    border-radius: 10px;
    margin-bottom: 10px;
    padding-top: 5px;
}

.flagimg {
    width: 15px;
    height: 15px;
    float: left;
    margin-top: 2px;
    margin-right: 5px;
}

.flagimg2 {
    width: 25px;
    height: 25px;
    float: left;
}

.gridflagdata {
    color: black;
    font-weight: normal;
    margin-left: 15px;
}

.griddata {
    color: black;
    font-weight: normal;
}

.gridstatus {
    background-color: #cce4d4;
    color: #99bda3;
    border-radius: 10px;
    font-size: 6pt;
    text-align: center;
}

.stockwhitebox {
    background-color: white;
    border-radius: 30px;
    padding: 25px;
}

.stockgreybox {
    background-color: #e9e9e9;
    border-radius: 20px;
    padding: 10px 20px;
}

.stockprice {
    text-align: center;
    background-color: white;
    border-radius: 20px;
    font-size: 10pt;
}

.stockname1 {
    margin-left: 35px;
    width: 50px;
}

.stockname2 {
    margin-left: 35px;
    width: 30px;
}

.stockname3 {
    margin-left: 35px;
    width: 80px;
}

.logincontainer {
    position: relative;
    height: 100%;
    background-color: #f8f4fc;
}

.loginheader {
    float: left;
    margin-top: 40px;
    margin-left: 30px;
}

.loginimgdiv {
    float: right;
    margin-top: 35px;
}

.loginbox {
    height: 100%;
    max-height: 500px;
    width: 100%;
    background-color: white;
    bottom: 0px;
    position: absolute;
    padding: 30px 40px;
    text-align: center;
    border-radius: 60px 60px 0px 0px;
    box-shadow: 0px -6px 10px lightgray;
}

@media (max-width:360px) {
    .loginminheight {
        height: 100vh;
    }

    .loginboxmin {
        height: 100%;
        max-height: 566px;
        width: 100%;
        background-color: white;
        bottom: 0px;
        position: absolute;
        padding: 30px 40px;
        text-align: center;
        border-radius: 60px 60px 0px 0px;
        box-shadow: 0px -6px 10px lightgray;
    }
}

.eyeicon {
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.eyeicon:focus {
    outline: none;
}

.loginsuccessbox {
    height: auto;
    width: 100%;
    background-color: white;
    bottom: 0px;
    position: absolute;
    padding: 50px 40px;
    text-align: center;
    border-radius: 60px 60px 0px 0px;
    box-shadow: 0px -6px 10px lightgray;
}

.loginsuccessbtn {
    width: 60%;
    padding: 20px;
    background-color: #f1eefa;
    color: #7b5dd0;
    font-weight: bold;
    font-size: 11.5pt;
    border-radius: 30px;
    border: none;
}

.loginh4 {
    padding: 0px 20px;
}

.loginerror {
    padding: 0px 40px;
    color: red;
}

.logininputbox {
    background-color: #f0f0f0;
    line-height: 50px;
    border-radius: 15px;
}

.logininput1 {
    border: none;
    background-color: #f0f0f0;
    line-height: 50px;
    width: 80%;
    outline: none;
    padding-left: 20px;
    border-radius: 15px;
}

.logininput2 {
    border: none;
    background-color: #f0f0f0;
    line-height: 50px;
    width: 75%;
    outline: none;
    padding-left: 20px;
    border-radius: 15px;
}

.loginspan {
    display: block;
    margin-bottom: 1.2em;
}

.checkbox {
    margin-top: 8px;
    color: #9b89f0;
    float: left;
}

.checkboxlabel {
    font-size: 10pt;
    color: #9b89f0;
    float: left;
    line-height: 30px;
    margin-left: 5px;
}

.loginbtnactive {
    border-radius: 20px;
    background-color: #744ccc;
    font-weight: bold;
    font-size: 12pt;
    color: white;
    border: none;
    width: 100%;
    padding: 20px;
}

.loginbtn {
    border-radius: 20px;
    background-color: #f1eefa;
    font-weight: bold;
    font-size: 12pt;
    color: #ae9be3;
    border: none;
    width: 100%;
    padding: 20px;
}

.inputbox::placeholder {
    color: black;
}

.flex {
    display: flex;
}

.submitbtn {
    border-radius: 20px;
    background-color: #6c48cb;
    font-size: 11pt;
    color: white;
    border: none;
    padding: 10px 30px;
}

.submitbtngrey {
    border-radius: 20px;
    background-color: #f1eefa;
    font-size: 11pt;
    color: #b6a5e6;
    border: none;
    padding: 10px 30px;
}

.cancelbtn {
    margin-left: 20px;
    border-radius: 20px;
    background-color: #f1eefa;
    font-size: 9pt;
    color: #6c48cb;
    border: none;
    padding: 8px 25px;
}

.purplebox {
    color: #6c48cb;
}

.currencymyr {
    text-align: center;
    border: none;
    background-color: #f4f6fa;
    font-size: 24pt;
    font-weight: bold;
    width: 100%;
}

.currencymyr:focus {
    outline: none;
}

.currencygrey {
    color: #7a7b7d;
}

.currencycountry {
    line-height: 0px;
    padding-top: 12px;
}

.currencyinfo {
    line-height: 0px;
    padding-top: 12px;
}

.currencytd {
    padding: 5px 0px;
}

.currencyprice {
    font-size: 10.5pt;
    text-align: right;
}

.line-chart .chartjs-render-monitor .chartjs-size-monitor,
.line-chart .chartjs-render-monitor .chartjs-size-monitor-expand,
.line-chart .chartjs-render-monitor .chartjs-size-monitor-shrink,
.line-chart .chartjs-render-monitor .chartjs-canvas {
    border: none !important;
}

.line-chart {
    border: none;
}

td {
    padding: 5px 15px;
}

tbody td:first-child,
tfoot td:first-child {
    border-radius: 10px 0 0 10px;
}

tbody td:last-child,
tfoot td:last-child {
    border-radius: 0 10px 10px 0;
}

tbody tr:nth-of-type(odd) {
    background-color: #f4f6fa;
}

.currencytable {
    border-collapse: separate;
    border-spacing: 0 12px;
}

.currencytable tbody tr {
    background-color: #f4f6fa;
}

.overflow {
    overflow: auto;
}

.transtable {
    overflow: auto;
    height: 50vh;
    min-width: 300px;
    width: 100%;
    margin: 20px 0px;
}

.transtable-header {
    display: -webkit-box;
    padding: 0px 10px;
    color: #777777;
    width: 100%;
}

.transtable-header-padding {
    padding-bottom: 18px;
}

.transtable-1 {
    width: 23%;
}

.transtable-2 {
    width: 26%;
}

.transtable-3 {
    width: 27%;
}

.transtable-4 {
    width: 19%;
}

.transtable-5 {
    width: 5%;
    text-align: right;
}

.transtable-scroll {
    display: inline-grid;
    width: 100%;
}

.transtable-data-purple {
    border-radius: 15px;
    background: #f4f6fa;
    padding: 10px;
    display: -webkit-box;
}

.transtable-data-red {
    border-radius: 15px;
    background: #f5d0ca;
    padding: 10px;
    display: -webkit-box;
}

.transtable-data-padding {
    padding: 5px 0px;
}

.transtable-txnno {
    color: #8163d2;
}

.records {
    color: #8f9192;
}

.autoright {
    margin-right: auto;
}

.marginleft10 {
    margin-left: 10px;
}

.marginleft15 {
    margin-left: 15px;
}

.marginleft50 {
    margin-left: 50px;
}

.addtransbtn {
    display: flex;
    margin-left: auto;
    border-radius: 20px;
    color: white;
    background-color: #6c48cb;
    border: none;
    padding: 10px 15px;
    font-size: 9pt;
}

.transbtn {
    border-radius: 20px;
    padding: 0px 30px;
    font-weight: normal;
}

.tableheader {
    color: #686868;
    font-weight: normal;
    font-size: 9.5pt;
}

.tablefooter {
    font-size: 9pt;
    background-color: #d2c7ef;
    font-weight: bold;
    border-radius: 20px;
}

.page {
    float: left;
    font-weight: normal;
    padding: 5px 10px;
}

.pagenoactive {
    float: left;
    font-weight: normal;
    margin-left: 10px;
    border-radius: 50px;
    background-color: #f4f6fa;
    color: #535353;
    padding: 5px 10px;
}

.pageno {
    float: left;
    font-weight: normal;
    margin-left: 10px;
    color: #535353;
    padding: 5px 10px;
}

.pointer {
    cursor: pointer;
}

.relative {
    position: relative;
}

.noti {
    background-color: white;
    border-radius: 30px;
    padding: 25px 50px;
    text-align: center;
}

.successnoti {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -10%);
}

.mantine-1sd8r2y {
    padding: 0.75rem;
    background-color: #f4f6fa;
    border-radius: 1rem;
    border: none;
}

.msgbox {
    padding-top: 30px;
}

.msg {
    font-size: 11pt;
    padding: 30px 0px;
    /*max-width: 150px;*/
    margin: 0 auto;
}

.mantine-14zcsvh {
    max-width: none;
}

.receiptimg {
    background-color: #f4f6fa;
    border-radius: 20px;
    padding: 40px 10px;
}

.receiptsuccess {
    color: black;
    border: none;
    background-color: white;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 10pt;
}

.addtransdiv {
    /*position: fixed;*/
    /*bottom: 7rem;*/
    width: 100%;
    max-width: 650px;
    text-align: center;
}

.transstatus {
    text-align: center;
    font-size: 9pt;
    margin: 0 auto;
    width: fit-content;
    padding: 3px 15px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.transcurrency {
    font-size: calc(1.6em + 1vw);
}

.transdetails {
    color: #8f8f8f;
}

.transprice {
    font-size: calc(.9em + 0.5vw);
}

.countrytable tbody td {
    padding-left: 19px;
}

.div-table {
    display: table;
    width: 85%;
    background-color: #eee;
    border: 1px solid #666666;
    border-spacing: 5px;
}

.tr {
    display: table-row;
}

.tc {
    display: table-cell;
    vertical-align: top;
    background-color: #ccc;
    padding: 5px 10px;
}

.tc1 {
    width: 240px;
}

.tc4, .tc5, .tc6 {
    width: 80px;
}

.modalContent {
    border-radius: 30px !important;
}

.txnModal {
    padding: 10px 30px;
}

img.currency-img {
    height: auto;
    margin: 0px 5px;
}

.profit-summary {
    margin-top: 30px;
}

.profit-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.profit-row > div {
    margin: 0 15px;
}

.headercreditlimit {
    position: absolute;
    right: 10px;
    top: 5px;
}

.headercreditlimit h5 {
    color: #56aa68;
    font-size: 13px;
}

@keyframes example {
    0% {
        bottom: 0em;
    }

    25% {
        bottom: 8em;
    }

    75% {
        bottom: 8em
    }

    100% {
        bottom: 0em;
    }
}

@media (max-width:767px) {
    .pricestyle {
        font-size: 1.6rem;
    }
}

@media (max-width: 575px) {
    .textalignmin {
        text-align: left;
    }
}

@media (min-width: 576px) {
    .textalignmax {
        text-align: right;
    }
}